/* eslint-disable react/no-danger */
import React from 'react';

import { ZOLA_DOMAIN } from '~/constants/url';
import {
  FAQPage,
  WithContext,
  Question,
  Article,
  BreadcrumbList,
  WebSite,
  ItemList,
  WebPage,
} from 'schema-dts';
import { ImageFieldType, LinkFieldType } from '~/libs/contentful/types';

export const renderJsonLdScript = (
  structuredData:
    | WithContext<FAQPage>
    | WithContext<Article>
    | WithContext<BreadcrumbList>
    | ItemList[]
    | WebSite
    | WebPage
): JSX.Element => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
  />
);

type Faq = {
  question: string;
  answer: string;
};

export function prependUrl(url: string | undefined): string | undefined {
  if (!url) return url;
  if (url.indexOf('https://') === 0) return url;
  if (url.indexOf('//') === 0) return `https:${url}`;
  return `https://${url}`;
}

const baseStructuredData: { '@context': 'https://schema.org' } = {
  '@context': 'https://schema.org',
};

/**
 * FAQ Documentation
 * @see https://developers.google.com/search/docs/data-types/faqpage
 * @example
 * const faqStructuredData = getFaqStructuredData(FAQ_LD.homepage);
 */
export function getFaqStructuredData(faqArray: Faq[]): WithContext<FAQPage> {
  function mapFaqItem(faqItem: Faq): Question {
    return {
      '@type': 'Question',
      name: faqItem.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faqItem.answer,
      },
    };
  }

  return {
    ...baseStructuredData,
    '@type': 'FAQPage',
    mainEntity: faqArray.map(mapFaqItem),
  };
}

export type Breadcrumb = {
  label?: string;
  to?: string;
};

export function getBreadcrumbStructuredData(
  breadcrumbArray: Breadcrumb[]
): WithContext<BreadcrumbList> {
  return {
    ...baseStructuredData,
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbArray.map((item, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      name: item?.label,
      item: `${ZOLA_DOMAIN}${item?.to}`,
    })),
  };
}

export type ArticleStructuredDataProps = {
  articleBody?: string;
  author?: string;
  excerpt: string;
  fullPathname: string;
  heroImage?: ImageFieldType;
  publishedAt?: string;
  subtopic?: LinkFieldType;
  title: string;
  updatedAt: string;
};

export function getArticleStructuredData({
  articleBody,
  author,
  excerpt,
  fullPathname,
  heroImage,
  publishedAt,
  subtopic,
  title,
  updatedAt,
}: ArticleStructuredDataProps): WithContext<Article> {
  const articleImage = heroImage?.src ? [prependUrl(heroImage.src) as string] : undefined;
  return {
    ...baseStructuredData,
    '@type': 'Article',
    articleBody,
    articleSection: subtopic?.name,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': fullPathname,
    },
    headline: title,
    description: excerpt,
    image: { '@type': 'ImageObject', url: articleImage },
    inLanguage: 'en',
    isAccessibleForFree: true,
    dateModified: updatedAt,
    datePublished: publishedAt,
    author: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Zola, Inc.',
      url: ZOLA_DOMAIN,
      logo: {
        '@type': 'ImageObject',
        url: 'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-logomark-marine.svg',
        width: '600',
        height: '92',
      },
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'US',
        addressLocality: 'New York',
        addressRegion: 'NY',
        postalCode: '10007',
        streetAddress: '7WTC, 250 Greenwich St., 39th Floor',
      },
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'Customer Service',
        telephone: '+1-408-657-9652',
        email: 'support@zola.com',
      },
      sameAs: [
        'https://www.facebook.com/zola',
        'https://www.pinterest.com/zola',
        'https://www.twitter.com/zola',
        'https://www.instagram.com/zola',
        'https://www.tiktok.com/@zola',
        'https://www.linkedin.com/company/zola-com/',
      ],
    },
  };
}
